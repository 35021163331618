<template>
  <v-list v-if="sDate" color="transparent" dense>
    <v-list-item :to="obRoute" target="_blank">
      <v-list-item-icon :class="obClass" class="mr-2 align-center">
        <icon-checkbox-checked v-if="value.is_signed" size="1.2em" />
        <icon-edit-box v-else size="1.2em" />
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="primary--text" v-text="sDate" />
        <v-list-item-subtitle>
          <span class="font-weight-bold" v-text="itemInvoiceType?.short_name" />
          <v-chip v-if="sNumber" class="ml-3" label small>{{ sNumber }}</v-chip>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import type { InvoiceData, InvoiceTypeData } from "@planetadeleste/vue-mc-gw";
import { Invoice } from "@planetadeleste/vue-mc-gw";
import dayjs from "dayjs";

@Component
export default class ContractInvoicePreview extends Vue {
  @Prop(Object) readonly value!: Partial<InvoiceData> | Invoice;

  get itemInvoiceType(): Partial<InvoiceTypeData> | null {
    return this.value instanceof Invoice
      ? this.value.get("invoice_type")
      : this.$_.get(this.value, "invoice_type", null);
  }

  get sDate(): string | undefined {
    return this.value?.created_at
      ? dayjs(this.value.created_at).format("DD/MM/YYYY")
      : undefined;
  }

  get sNumber(): string | undefined {
    return this.value?.order_serial
      ? `${this.value.order_number}${this.value.order_serial}`
      : undefined;
  }

  get obRoute() {
    if (!this.value?.id) {
      return undefined;
    }

    return { name: "invoices.update", params: { id: this.value.id } };
  }

  get obClass() {
    return {
      "success--text": this.value?.is_signed,
      "grey--text": !this.value?.is_signed,
    };
  }
}
</script>
