import { Component, Mixins } from "vue-property-decorator";
import ModelMixin from "@/mixins/ModelMixin";
import type { VueClass } from "vue-class-component/lib/declarations";
import type { ContractData } from "@planetadeleste/vue-mc-gw";
import { Contract, ContractCollection } from "@planetadeleste/vue-mc-gw";
import { route } from "@/services/laroute";
import type { AxiosRequestConfig } from "axios";
import { isNumber } from "lodash";
import { cleanCache } from "@/plugins/axios";

const TypedModelMixin = ModelMixin as unknown as VueClass<
  ModelMixin<Contract, ContractCollection>
>;

@Component
export default class ContractsMixin extends Mixins(TypedModelMixin) {
  obCollection = new ContractCollection();
  obModelClass = Contract;
  sRoutePath = "/contracts";

  async onCreateInvoices(
    sValue: number | Record<string, any>
  ): Promise<undefined | Partial<ContractData>> {
    const obData: Record<string, any> = isNumber(sValue)
      ? { id: sValue }
      : sValue;

    // Set axios config to use a custom route request on invoice model
    const obConfig: AxiosRequestConfig = {
      url: route("contracts.createinvoices", obData),
      method: "POST",
      data: obData,
    };
    const obContract = new Contract();
    const obResponse = await obContract.createRequest(obConfig).send();

    if (obResponse?.getData()) {
      const sMessage = obResponse.getData().message;
      cleanCache();

      if (sMessage) {
        this.$toast.success(sMessage);
      }

      return obResponse.getData().data as Partial<ContractData>;
    }

    return undefined;
  }

  created() {
    this.onCreated();
  }
}
